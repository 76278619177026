<template>
  <div>
    <h5 class="info-text">Let's start with the customer information</h5>
    <div v-if="experiment.customer" class="selected-customer center">
      <h6>Selected customer</h6>
      <div class="picture">
        <img :src="experiment.customer.logo" class="picture-src" />
      </div>
      <p>{{ experiment.customer.name }}</p>
      <md-button v-on:click="changeCustomer()" class="md-sm md-default"><md-icon>clear</md-icon>Change</md-button>
    </div>
    <div v-else>
      <div v-if="customersLoaded">
        <div v-if="select">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto">
              <drop-down direction="down">
                <select-customer @select="selectCustomer"></select-customer>
              </drop-down>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 mx-auto">
              <h5 class="or center"><span>or</span></h5>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 mx-auto center">
              <md-button v-on:click="select=!select" class="md-sm md-default"><md-icon>add</md-icon>add a new one</md-button>
            </div>
          </div>
        </div>
        <div v-else class="md-layout">
          <form>
            <div class="md-layout-item md-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-40 md-small-size-100">
                  <div class="picture-container">
                    <div class="picture">
                      <div v-if="!image">
                        <img :src="logo" class="picture-src" title="">
                      </div>
                      <div v-else>
                        <img :src="image" />
                      </div>
                      <input type="file" @change="onFileChange">
                    </div>
                    <h6 class="description">Choose logo</h6>
                  </div>
                </div>
                <div class="md-layout-item md-size-60 mt-4 md-small-size-100">
                  <md-field :class="[
                    {'md-valid': !errors.has('name') && touched.name},
                    {'md-form-group': true},
                    {'md-error': errors.has('name')}]">
                    <md-icon>copyright</md-icon>
                    <label>Customer Name</label>
                    <md-input
                      v-model="name"
                      data-vv-name="name"
                      type="text"
                      name="name"
                      required
                      v-validate="modelValidations.name">
                    </md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100 mx-auto center add-customer-buttons">
              <md-button v-on:click="select=!select" class="md-sm md-default"><md-icon>clear</md-icon>Cancel</md-button>
              <md-button native-type="submit" @click.native.prevent="validateAddCustomerForm" class="md-sm md-success"><md-icon>add</md-icon>Add and select</md-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import swal from 'sweetalert2'
import SelectCustomer from '../../Customers/SelectCustomer.vue'

  export default {
    components: {
      SlideYDownTransition,
      SelectCustomer
    },
    props: {
      logo: {
        type: String,
        default: './img/customer.png'
      }
    },
    data() {
      return {
        experiment: {
          customer: null
        },
        customersLoaded: false,
        image: '',
        single: null,
        select: true,
        customers: [],
        name: '',
        touched: {
          name: false
        },
        modelValidations: {
          name: {
            required: true
          }
        }
      }
    },
    created() {
      this.loadCustomers()
    },
    methods: {
      loadCustomers () {
        var self = this
        var xhr = new XMLHttpRequest()
        xhr.open('GET', this.$root.apiUrl + 'v1/customers')
        xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
        xhr.onload = function () {
          if (xhr.status === 401) self.$router.push({name: 'Login'})
          var response = JSON.parse(xhr.responseText)
          if (xhr.status === 200 && response.response) {
            self.customers = response.response
          } else if (xhr.status === 404) {
            self.select = false
            self.customers = []
          } else {
            var message
            if (response.response && response.response[0] && response.response[0].message)
              message = response.response[0].message
            else
              message = 'Unknown error'
            self.showSweetAlertError(message)
          }
          self.customersLoaded = true
        }
        xhr.send()
      },
      selectCustomer (customer) {
        this.experiment.customer = customer
      },
      changeCustomer () {
        this.select = true
        this.experiment.customer = null
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'OK',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      },
      handlePreview(file) {
        this.model.imageUrl = URL.createObjectURL(file.raw);
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validateAddCustomerForm() {
        return this.$validator.validateAll().then(res => {
          if(res === true) {
            this.createCustomer()
          }
          return res
        })
      },
      validate() {
        if(this.experiment.customer !== null) {
          this.$emit('on-validated', this.experiment, true)
          return true
        }
        else {
          this.showSweetAlertError('There is no customer selected')
          return false
        }
      },
      createCustomer() {
        var self = this
        var formData = new FormData()
        formData.append('name', this.name)
        if (this.image !== '') formData.append('image', this.image)
        var xhr = new XMLHttpRequest()
        xhr.open('POST', this.$root.apiUrl + 'v1/customers')
        xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
        xhr.onload = function () {
          if (xhr.status === 401) self.$router.push({name: 'Login'})
          var response = JSON.parse(xhr.responseText)
          if (xhr.status === 201) {
            self.experiment.customer = response.response
            self.select = true
            self.name = ''
            self.image = ''
            self.untouchName()
            self.loadCustomers()
            self.createCustomerSuccessNotify('top','right')
          } else {
            var message
            if (response.response && response.response[0] && response.response[0].message)
              message = response.response[0].message
            else
              message = 'Unknown error'
            self.showSweetAlertError(message)
          }
        }
        xhr.send(formData)
      },
      untouchName () {
        // to nie działa - do rozkminienia
        this.touched.name = false
      },
      createCustomerSuccessNotify (verticalAlign, horizontalAlign) {
        this.$notify(
          {
            message: 'Customer added succesfully.',
            icon: 'copyright',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: 'success'
          }
        )
      },
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
        return;
        this.createImage(files[0]);
      },
      createImage(file) {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    watch: {
      name () {
        this.touched.name = true
      }
    }
  }
</script>
<style>
.center {
  text-align: center;
}
.or {
  margin-bottom: 30px;
}
.or span {
  position: relative;
}
.or span::before {
  content:"";
  display: block;
  width: 50px;
  height: 1px;
  border-bottom: 1px #ccc solid;
  position: absolute;
  top: 10px;
  right: 24px;
}
.or span::after {
  content:"";
  display: block;
  width: 50px;
  height: 1px;
  border-bottom: 1px #ccc solid;
  position: absolute;
  top: 10px;
  left: 24px;
}
.customers-list li a img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.selected-customer img {
  width: 100px;
  height: 100px;
}
.add-customer-buttons {
  margin-top: 20px;
}
.add-customer-buttons button {
  margin-left: 5px;
  margin-right: 5px;
}
.picture div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.picture div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.selected-customer .picture:hover {
  border-color: #ccc !important;
}
form {
  width: 100%;
}
h5.info-text {
  margin-bottom: 0 !important;
}
</style>
