<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 mx-auto">
      <md-card>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search templates"
                  v-model="searchQuery">
                </md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Tags"><md-chips v-model="item.tags" class="md-success static" :mdStatic="true"></md-chips></md-table-cell>
              <md-table-cell md-label="Select" class="select-cell">
                <md-button class="md-just-icon md-simple md-success" @click.native="selectTemplate(item.code, item.name)"><md-icon>input</md-icon></md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
          <pagination class="pagination-no-border pagination-success"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import {Pagination} from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'

export default {
  name: 'select-template',
  props: {
    templateType: {
      type: String
    }
  },
  data() {
    return {
      tableData: [],
      currentSort: 'created_at',
      currentSortOrder: 'desc',
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'tags'],
      searchedData: [],
      fuseSearch: null
    }
  },
  components: {
    Pagination
  },
  computed: {
    queriedData () {
      let result = this.tableData;
      if(this.searchedData.length > 0){
        result = this.searchedData;
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
    }
  },
  created () {
    this.loadTemplates()
  },
  methods: {
    loadTemplates () {
      var self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/templates?filters=type:' + this.templateType + '&sort_by=id.desc&paginate=true')
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = function () {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response && response.response.items) {
          response.response.items.forEach(item => {
            item.tags = item.tags.split(',')
            self.tableData.push(item)
          })
          self.fuseSearch = new Fuse(self.tableData, {keys: ['name', 'tags'], threshold: 0.3})
        } else if (xhr.status === 404) {
          self.tableData = []
        } else {
          var message
          if (response.response && response.response[0] && response.response[0].message)
            message = response.response[0].message
          else
            message = 'Unknown error'
          self.showSweetAlertError(message)
        }
      }
      xhr.send()
    },
    selectTemplate (code, name) {
      this.$emit('select', code, name)
    },
    customSort (value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    showSweetAlertError (message) {
      swal({
        title: 'An error occured!',
        text: message,
        type: 'warning',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Try again',
        cancelButtonClass: 'md-button md-success',
        buttonsStyling: false
      })
    }
  },
  watch: {
    /**
      * Searches through the table data by a given query.
      * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
      * @param value of the query
      */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result;
    },
    templateType(type) {
      this.templateType = type;
    }
  }
}
</script>

<style lang="scss" scoped>
.paginated-table table > tbody > tr > td {
  width: auto;
}
.md-table-toolbar {
  margin-bottom: 30px;
}
.md-card {
  margin: 0;
  box-shadow: none;
}
.md-card-content {
  padding: 0;
}
.md-card-actions {
  margin: 0;
}
.select-cell .md-button {
  border: 1px #ddd solid;
  margin: 2px 0px 2px 5px;
  background-color: #fff !important;
  padding: 2px;
}
.select-cell .md-button:hover {
  background-color: #ddd !important;
}
</style>
<style lang="scss">
.md-field.md-chips.static {
  padding: 0 !important;
  position: relative;
  top: -5px;
  text-transform: uppercase;
  font-weight: 500;
}
.static .md-chip {
  margin-top: 0;
  font-size: 10px !important;
}
</style>
