<template>
  <div>
    <h5 class="info-text">Choose one of the predefined templates</h5>
    <div class="md-layout">
      <div v-if="template.template===null" class="md-layout-item md-size-100 mx-auto">
        <select-template :templateType="'experiment'" @select="selectTemplate"></select-template>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 mx-auto">
            <h5 class="or center"><span>or</span></h5>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 mx-auto center">
            <md-button v-on:click="selectTemplate('', 'Empty template')" class="md-sm md-default">empty template</md-button>
          </div>
        </div>
      </div>
      <div v-else class="md-layout-item md-size-55 md-small-size-100 mx-auto center">
        <h6>Selected template</h6>
        <p><md-icon class="green">done</md-icon> {{ template.template.name }}</p>
        <md-button v-on:click="changeTemplate()" class="md-sm md-default"><md-icon>clear</md-icon>Change</md-button>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert2'
import SelectTemplate from '../../Templates/SelectTemplate.vue'

  export default {
    components: {
      SelectTemplate
    },
    data() {
      return {
        template: {
          template: null
        }
      }
    },
    methods: {
      selectTemplate(code, name) {
        this.template.template = {
          code: code,
          name: name
        }
      },
      changeTemplate() {
        this.template.template = null
      },
      validate() {
        if(this.template.template !== null) {
          this.$emit('on-validated', this.template, true)
          return Promise.resolve(true)
        }
        else {
          this.showSweetAlertError('There is no template selected')
          return Promise.resolve(true)
        }
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'OK',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      },
    }
  }
</script>
<style>
.note {
  text-align: center;
  position: relative;
  top: -25px;
}
.center {
  text-align: center;
}
.md-icon.green {
  color: #4caf50 !important;
}
</style>
