<template>
  <div>
    <h5 class="info-text">Enter some tags to make it easier to search for experiment</h5>
    <p class="note"><small>(press enter after each one)</small></p>
    <div class="md-layout">
      <div class="md-layout-item md-size-55 md-small-size-100 mx-auto">
        <md-chips v-model="tags.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        tags: {
          tags: []
        }
      }
    },
    methods: {
      validate() {
        this.$emit('on-validated', this.tags, true)
        return Promise.resolve(true)
      }
    }
  }
</script>
<style>
.note {
  text-align: center;
  position: relative;
  top: -25px;
}
</style>
